import {Avatar} from "ch3-ui-lib";
import {call} from "../../components/Dialer/services/sipService";
import {useCallOptions} from "../../components/Dialer/hooks/useCallOptions";
import {TelcoStatus} from "../../store/features/Team/teamSlice";

import {convertFromISOFormat} from "../../utilis/timeUtilis";
import {RootState, useAppDispatch} from "../../store/store";
import {
    getSelectedSpeaker,
    setCallFrom,
    setCallState,
    toggleDialerHidden
} from "../../store/features/calls/dialerSlice";
import {useSelector} from "react-redux";
import {hexToEmoji} from "../../components/Emoji/emoji";




const Team = ({user}: any) => {

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const audioDevice = useSelector(getSelectedSpeaker);
    const dispatch = useAppDispatch();

    const options = useCallOptions();
    const { dateString, timeString } = convertFromISOFormat(user?.customUserStatus?.dueDate || '');
    const expires = dateString ? `${dateString}, ${timeString}` : timeString;

    function handleCall() {
        if(isDialerHidden) {
            dispatch(toggleDialerHidden());
        }
        const currentUserId = localStorage.getItem('userId');
        dispatch(setCallFrom(`${user.firstName} ${user.lastName}`));
        call(user.userId, {
            ...options,
            fromUserName: currentUserId
        },audioDevice);
        dispatch(setCallState('CONNECTING'))
    }


    const getUserStatus = () =>
        user.doNotDisturbUserStatus.enabled ? TelcoStatus.DND : user.telcoUserStatus.status;


    return (

            <div className='border border-black m-5 max-w-[424px] h-[292px] flex flex-col'>
                <div className="pt-7">

                <Avatar className='pb-4 px-[130px]' size='xxl'  name={user.firstName + ' ' + user.lastName}  type={'initials'} color={'auto'} status={getUserStatus()}/>

                    <div className=' flex text-base font-semibold justify-center text-[18px]'>{user.firstName + ' ' + user.lastName}</div>
                    
                        {Boolean(user?.customUserStatus?.icon) &&
                        <div className="flex items-center flex-col">
                            <div className='flex font-medium justify-center gap-2'>{hexToEmoji(user.customUserStatus.icon, 'w-4 h-4') } {user.customUserStatus.name} </div>
                            <div className='text-sm text-grey-500'>
                                Expires: {expires}
                            </div>
                        </div>
}</div>
                {Boolean(user.telcoUserStatus.status === TelcoStatus.TALKING && !user.doNotDisturbUserStatus.enabled) &&
                    <div className='border-t border-black p-4 justify-center flex mt-auto'>
                    <i onClick={() => handleCall()} className='cursor-pointer bg-warning-400 text-white material-icons-sharp  p-3 rounded-full'>phone_in_talk</i>
                </div>

                }
                                {Boolean(!user.doNotDisturbUserStatus.enabled && user.telcoUserStatus.status !== TelcoStatus.TALKING) &&
                    <div className='border-t border-black p-4 justify-center flex mt-auto'>
                    <i  onClick={() => handleCall()} className='cursor-pointer bg-success-500 text-white material-icons-sharp  p-3 rounded-full'>call</i>
                </div>
                }
                                {Boolean(user.doNotDisturbUserStatus.enabled) &&
                    <div className='border-t border-black p-4 justify-center flex mt-auto'>
                    <i  className='cursor-pointer bg-error-400 text-white material-icons-sharp  p-3 rounded-full'>remove</i>
                </div>
                }
               

            </div>


    )
}

export default Team;
