import Header from "../../components/header/Header.tsx";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {Avatar, Button, ChInput, Icon, Switch} from "ch3-ui-lib";
import {mobileWidth, tabletWidth} from "../../utilis/screenWidthUtils.ts";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store.ts";
import {useTheme} from "next-themes";
import {
    fetchUserProfile,
    fetchUserProfileNotifications, updateMissedCallEmailNotificationEnabled, updateProfile
} from "../../store/features/ProfileSettings/profileSettingsSlice.tsx";
import {useForm, Controller} from "react-hook-form";
import {Colors} from "../../utilis/types/Types";
import {hexToEmoji} from "../../components/Emoji/emoji";

const profileMenu = [
    {
        icon: 'article',
        label: 'General information',
        subLabel: 'Full name, email, and role',
        value: 'GENERAL_INFORMATION'
    },
    {
        icon: 'call',
        label: 'Phone Numbers',
        subLabel: 'Your assigned numbers',
        value: 'PHONE_NUMBERS'
    },
    {
        icon: 'groups',
        label: 'Groups',
        subLabel: 'Your assigned groups',
        value: 'GROUPS'
    },
    {
        icon: 'notifications',
        label: 'Notification',
        subLabel: 'Alerts about calls',
        value: 'NOTIFICATION'
    },
    // {
    //     icon: 'settings',
    //     label: 'Personalization',
    //     subLabel: 'Customize your account settings',
    //     value: 'PERSONALIZATION'
    // }
];


const ProfileSettings = () => {


    const dispatch = useAppDispatch();

    const [state, setState] = useState('GENERAL_INFORMATION');
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const profileSettings = useSelector((state: RootState) => state.profileSettings);


    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchUserProfile());
            await dispatch(fetchUserProfileNotifications());
        }
        fetchData();
    }, [dispatch]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
    });


    useEffect(() => {
        setValue('firstName', profileSettings.firstName || '');
        setValue('lastName', profileSettings.lastName || '');
        setValue('email', profileSettings.email || '');
    }, [profileSettings]);


    const {theme} = useTheme();

    const handleSwitchChange = async () => {
        const userId = localStorage.getItem('userId')
        await dispatch(updateMissedCallEmailNotificationEnabled({
            userId: userId ?? '',
            missedCallEmailNotificationEnabled: !profileSettings.missedCallEmailNotificationEnabled
        }))
    }


    const onSubmit = async () => {

        const data = {
            userId: localStorage.getItem('userId'),
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
        };
        await dispatch(updateProfile(data));
    }


    return (
        <div className='w-full'>
            <Header header='You' avatar={
                <Avatar
                    size={"xl"}
                    type='initials'
                    name={profileSettings.firstName + ' ' + profileSettings.lastName}
                    color={Colors.auto}
                />
            }/>

            <div
                className={`h-[calc(100vh-300px)] mr-4 overflow-y-auto flex ${mobileWidth || tabletWidth ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>

                <div
                    className=" w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 inline-flex mx-8">

                    <div className="flex-col justify-start items-center gap-3 flex">
                        <Avatar size={"xxl"} type='initials'
                                name={profileSettings.firstName + ' ' + profileSettings.lastName}
                                color={Colors.auto}/>

                        <div className="flex-col justify-start items-center gap-2 flex">

                            <div
                                className={` text-center text-black text-xl font-semibold  leading-7 ${theme === 'dark' ? 'text-white' : ' '}`}>
                                {profileSettings.firstName} {profileSettings.lastName}
                            </div>
                        </div>
                    </div>


                    <div className="  self-stretch py-4 text-black">
                        {profileMenu.map((item) => (
                            <div key={item.value} onClick={() => setState(item.value)}
                                 className={`cursor-pointer flex gap-6 px-8 py-4 ${state === item.value && theme === 'light' ? 'bg-information-50' : ' '} ${state === item.value && theme === 'dark' ? 'bg-[#393C41]' : ' '}`}>
                                <Icon className={`${theme === 'dark' ? ' text-white' : ' '}`} iconName={item.icon}/>
                                <div className="flex flex-col ">
                                    <div
                                        className={`text-sm font-semibold leading-5 ${theme === 'dark' ? ' text-white' : ' '}`}>{item.label}</div>
                                    <div
                                        className={`mt-1 text-xs leading-5 ${theme === 'dark' ? ' text-white' : ' '}`}>{item.subLabel}</div>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>

                <div
                    className=" flex-col justify-start items-center gap-6 inline-flex px-8 w-full overflow-x-hidden">
                    {
                        Boolean(state === 'GENERAL_INFORMATION') &&
                        <div className="flex-col justify-start items-center gap-6 inline-flex mx-8 w-full">
                            <div className='w-full'>
                                <div
                                    className={`flex flex-col justify-between border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='font-semibold px-6 pt-6 text-lg'>
                                        General information
                                    </div>
                                    <div className='text-grey-500 px-6 pb-6 text-sm'>
                                        Update name and general information
                                    </div>
                                </div>
                                <div
                                    className={`w-full border border-black px-8 gap-6 grid py-8 ${theme === 'dark' ? ' border-white' : ' border-black'}`}>

                                    <div>
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            rules={{required: 'First name is required'}}
                                            render={({field}) => (
                                                <ChInput
                                                    {...field}
                                                    value={field.value}
                                                    callback={(value: any) => field.onChange(value)}
                                                    error={errors.firstName ? errors.firstName.message : undefined}
                                                    className='max-w-full' label='First name'/>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            rules={{required: 'Last name is required'}}
                                            render={({field}) => (
                                                <ChInput
                                                    {...field}
                                                    value={field.value}
                                                    callback={(value: any) => field.onChange(value)}
                                                    error={errors.lastName ? errors.lastName.message : undefined}
                                                    className='max-w-full' label='Last name'/>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{required: 'email is required'}}
                                            render={({field}) => (
                                                <ChInput value={field.value}
                                                         disabled
                                                         callback={(value: any) => field.onChange(value)}
                                                         className='max-w-full'
                                                         label='Email adress'/>
                                            )}
                                        />
                                    </div>
                                    {/*<div>*/}
                                    {/*    <Controller*/}
                                    {/*        name="role"*/}
                                    {/*        control={control}*/}
                                    {/*        rules={{ required: 'role is required' }}*/}
                                    {/*        render={({ field }) => (*/}
                                    {/*    <ChInput value={field.value}*/}
                                    {/*             callback={(value:any) => field.onChange(value)}*/}
                                    {/*             className='max-w-full mb-6' label='Role'/>*/}
                                    {/*        )}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'PHONE_NUMBERS') &&
                        <div
                            className={`  w-full border-x border-t  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className=''>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Assigned phone numbers
                                </div>
                                <div
                                    className={`font-semibold px-6 p-6 text-xs border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Phone numbers
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {profileSettings.msisdns.map((number) => (
                                            <div key={number.msisdnId}
                                                 className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                                <div className='flex gap-3 text-sm items-center pl-4 py-4'>
                                                    <div >{hexToEmoji(number.icon ?? '1F4F1')}</div>
                                                    {number.label}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'GROUPS') &&
                        <div
                            className={`w-full border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div className=''>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Assigned groups
                                </div>
                                <div
                                    className={`font-semibold px-6 p-6 text-xs border-b  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Groups
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {profileSettings.groups.map((group) => (
                                            <div
                                                className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                                <div className='flex gap-3 text-sm items-center pl-4 py-4'>
                                                    <Avatar size="xs" type='icon'
                                                            color={group.iconColor ?? Colors.auto}
                                                            icon='home'/>{group.name}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'NOTIFICATION') &&
                        <div className='w-full '>
                            <div
                                className={`flex flex-col justify-between border-x border-t  ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    Notification
                                </div>

                                <div
                                    className={`w-full border-b  p-6 gap-6 grid items-center ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='my-2'>
                                        <Switch size='lg' checked={profileSettings.missedCallEmailNotificationEnabled}
                                                callback={handleSwitchChange}
                                                label={'Email me about missed incoming calls'}
                                                alignLeft={false}></Switch>

                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {/* {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'PERSONALIZATION') &&
                        <div className='w-full '>
                            <div className={`flex flex-col justify-between border-x border-t ${theme === 'dark' ? ' border-white' : ' border-black'}`}  >
                                <div className={`font-semibold px-6 p-6 text-lg border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`} >
                                Personalization
                                </div>

                                <div className={`w-full border-b  p-6 gap-6 grid items-center ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                                    <div className='my-2'>
                                        <div>
                                            <div>
                                                Theme
                                            </div>
                                            <div>
                                                Choose your application's color scheme.
                                             </div>
                                        </div>
                                        <ThemeSwitcher></ThemeSwitcher>


                                    </div>
                                </div>

                            </div>

                        </div>
                    } */}
                </div>


            </div>
            <div className={` flex justify-end ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
                <div className={`mx-8 mt-8 flex  justify-between`}>

                    <div className='flex gap-4'>
                        <Link to={'/groups'}>
                            <Button size={'large'} label='Cancel' buttonType='secondary'/>
                        </Link>
                        <Button size={'large'} onClick={handleSubmit(onSubmit)} label='Update settings'
                                buttonType='primary'/>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileSettings;
